.privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  h1, h2 {
    color: #2b2b2b;
  }
  
  h1 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 20px;
    margin-top: 30px;
  }
  
  p, ul {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  strong {
    color: #ff5722;
  }
  
  a {
    color: #ff5722;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Styles */
  @media (max-width: 600px) {
    .privacy-container {
      padding: 15px;
    }
  
    h1 {
      font-size: 24px;
    }
  
    h2 {
      font-size: 18px;
    }
  
    p, ul {
      font-size: 14px;
    }
  }
  