.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  h1, h2 {
    color: #2b2b2b;
  }
  
  h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-top: 30px;
  }
  
  p {
    color: #555;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  strong {
    color: #ff5722;
  }
  
  section {
    margin-bottom: 40px;
  }
  
  /* Responsive Styles */
  @media (max-width: 600px) {
    .about-us-container {
      padding: 15px;
    }
  
    h1 {
      font-size: 28px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    p {
      font-size: 14px;
    }
  }
  